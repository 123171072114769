.App {
  text-align: center;
}

.App-logo {
  height: 80vmin;
  pointer-events: none;
}

.App-Down {
  color: white;
  height: 20vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-Down {
    animation: App-Down-shift infinite 5s linear;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes App-Down-shift {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  15%
    {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
    30%
    {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    50%
    {
        -webkit-transform: translateY(3px);
        transform: translateY(3px);
    }
    65%
    {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px);
    }
    80%
    {
        -webkit-transform: translateY(2px);
        transform: translateY(2px);
    }
    100%
    {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
